import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import LinkTile from '../../../components/LinkTile/LinkTile';
import Button from '../../../components/Button/Button';
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

const DoINeedALicencePage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			builderImg: file(relativePath: { eq: "builders_closeUp01.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Layout
			view='builder'
			breadcrumb={{
				text: <FormattedMessage id='builderNav.dashboard' />,
				path: '/builder-vendor/dashboard/'
			}}
			header={
				<>
					<FormattedMessage id='builderNav.becomeA' />{' '}
					<span style={{ fontWeight: `bold` }}>
						<FormattedMessage id='builderNav.becomeB' />
					</span>
				</>
			}
		>
			<SEO lang={intl.locale} title={`${intl.formatMessage({ id: 'builderNav.step1' })}?`} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: `${intl.formatMessage({
										id: 'builderNav.step1'
									})}?`,
									path: '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence',
									active: true
								},
								{
									text: <FormattedMessage id='builderNav.step2' />,
									path: '/builder-vendor/become-a-licensed-builder/before-you-apply'
								},
								{
									text: <FormattedMessage id='builderNav.step3' />,
									path: '/builder-vendor/become-a-licensed-builder/applying-for-a-licence'
								},
								{
									text: <FormattedMessage id='builderNav.step4' />,
									path:
										'/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'
								},
								{
									text: <FormattedMessage id='builderNav.step5' />,
									path: '/builder-vendor/become-a-licensed-builder/after-licensing'
								}
							]}
						/>
					</Col>
				</Row>

				<Row align='center'>
					<Col lg={8}>
						<h2>
							<FormattedMessage id='becomeBuilder1.headerA' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder1.contentA' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='becomeBuilder1.list01A' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder1.list01B' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder1.list01C' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder1.list01D' />
							</li>
						</ul>
					</Col>

					<Col lg={4} md={12}>
						<Img fluid={data.builderImg.childImageSharp.fluid} alt='' />
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<p>
							<FormattedMessage id='becomeBuilder1.contentB' />
						</p>

						<p>
							<FormattedMessage id='becomeBuilder1.contentC' />
						</p>
						<Button
							id='ownerInterpretationLink'
							linkPath='/licensing-compliance/licensing/'
							text={<FormattedMessage id='becomeBuilder1.contentD' />}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<p>
							<FormattedMessage id='becomeBuilder1.contentE' />{' '}
							<b>
								<FormattedMessage id='becomeBuilder1.contentH' />
							</b>{' '}
							<FormattedMessage id='becomeBuilder1.contentF' />{' '}
							<Link to='/builder-vendor/new-application-checklist/'>
								<FormattedMessage id='becomeBuilder1.contentJ' />
							</Link>
							. <FormattedMessage id='becomeBuilder1.contentK' />
						</p>
						<LinkTile
							path={`../../../../${intl.formatMessage({
								id: 'linkNames.newInstructionLink'
							})}`}
						>
							<h3>
								<FormattedMessage id='linkNames.newInstructionGuide' />
							</h3>
						</LinkTile>
					</Col>
					<Col>
						<p>
							<FormattedMessage id='becomeBuilder1.contentG' />{' '}
							<b>
								<FormattedMessage id='becomeBuilder1.contentI' />
							</b>{' '}
							<FormattedMessage id='becomeBuilder1.contentF' />{' '}
							<Link to='/builder-vendor/renewal-application-checklist/'>
								<FormattedMessage id='becomeBuilder1.contentJ' />
							</Link>
							. <FormattedMessage id='becomeBuilder1.contentL' />
						</p>
						<LinkTile
							path={`../../../../${intl.formatMessage({
								id: 'linkNames.renewInstructionLink'
							})}`}
						>
							<h3>
								<FormattedMessage id='linkNames.renewInstructionGuide' />
							</h3>
						</LinkTile>
					</Col>
				</Row>
			</Container>

			<Container>
				<Row>
					<Col md={12}>
						<SubPageBottomNav
							nextLink={{
								text: <FormattedMessage id='builderNav.step2' />,
								path: '/builder-vendor/become-a-licensed-builder/before-you-apply'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default DoINeedALicencePage;
